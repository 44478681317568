import React from 'react'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import styled, { linkStyles } from '../../../src/constants/theme'
import { cardWithLeftHighlightStyles } from '../../../styles'
import FooterDiv from '../elements/FooterDiv'
import { PageHeader } from '../pages/PageParts'

const ResendForgotPasswordInstructions: React.FC = () => {
  const i18n = useI18n()

  return (
    <React.Fragment>
      <PageHeader>{i18n.t('forgotPasswordPage.emailSentNotice')}</PageHeader>
      <Card>
        <h2>{i18n.t('forgotPasswordPage.nowWhat')}</h2>
        <p>
          <b>{i18n.t('forgotPasswordPage.emailInstructions')}</b>
        </p>

        <p>
          {i18n.t('forgotPasswordPage.spamNotice')}{' '}
          <a href="/password/new">
            {i18n.t('forgotPasswordPage.spamLinkText')}
          </a>
        </p>
      </Card>
      <FooterDiv>
        <a href="/sign_in">{i18n.t('signIn')}</a>
        <RequestLinkWrapper>
          {i18n.t('needInvitation')}{' '}
          <a href="/invitation/resend">{i18n.t('sendRequest')}</a>
        </RequestLinkWrapper>
      </FooterDiv>
    </React.Fragment>
  )
}

const Card = styled.div(({ theme }) => ({
  ...cardWithLeftHighlightStyles({ theme }),
  alignItems: 'space-between',
  backgroundColor: theme.background,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.padding,

  '& > *': {
    padding: theme.paddingSmall,
    paddingTop: 0,
  },

  '& > h2': {
    paddingBottom: theme.padding,
  },

  '& a': {
    ...linkStyles,
  },
}))

const RequestLinkWrapper = styled.div(({ theme }) => ({
  fontSize: 13,
  paddingTop: theme.padding,
}))

export default ResendForgotPasswordInstructions
