import { ApolloLink } from '@apollo/client'
import { getPreferredLanguage } from '../../src/lib/storage'

const preferredLanguageLink = new ApolloLink((operation, forward) => {
  const preferredLanguage = getPreferredLanguage()
  if (preferredLanguage) {
    operation.setContext((prevContext) => {
      const headers = prevContext.headers || {}
      headers['Accept-Language'] = preferredLanguage

      return { ...prevContext, headers }
    })
  }

  return forward(operation)
})

export default preferredLanguageLink
