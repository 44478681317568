import React from 'react'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import { useCustomer } from '../../../configuration/hooks/useCustomer'

interface LinkShape {
  href: string
  text: string
}

const TermsAndPrivacyPolicy: React.FC = () => {
  const i18n = useI18n()

  const {
    informedConsent,
    noticeOfPrivacyPractices,
    patientFinancialResponsibility,
    termsAndAgreementsEnabled,
  } = useCustomer() || {}

  if (!termsAndAgreementsEnabled) {
    return null
  }

  const links: LinkShape[] = []

  informedConsent &&
    links.push({
      href: informedConsent,
      text: i18n.t('termsAndPrivacyPolicy.informedConsent'),
    })

  noticeOfPrivacyPractices &&
    links.push({
      href: noticeOfPrivacyPractices,
      text: i18n.t('termsAndPrivacyPolicy.noticeOfPrivacy'),
    })

  patientFinancialResponsibility &&
    links.push({
      href: patientFinancialResponsibility,
      text: i18n.t('termsAndPrivacyPolicy.patientFinancial'),
    })

  return (
    <>
      {links.map((link: LinkShape, idx) => (
        <a
          key={idx}
          href={link.href}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {link.text}
          {idx === links.length - 1 ? null : ' | '}
        </a>
      ))}
    </>
  )
}

export default TermsAndPrivacyPolicy
