import { InvitationTokenResponse } from '@app/graphql-types/Account'
import React from 'react'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import {
  BasicType,
  ElementType,
} from '../../../src/components/forms/FormElementParts'
import { Form, FormIdentifier } from './FieldFactory'
import FormBuilder from './FormBuilder'

interface Props {
  agreementsConfig?: InvitationTokenResponse
  clearServerError: any
  formIdentifier: FormIdentifier
  handleSubmit: any
  serverError: any
}

const registerInvitationForm = (
  agreementsConfig,
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Form => {
  const { privacyPolicy, termsOfService } = agreementsConfig || {}
  let termsLabel = ''
  if (privacyPolicy && termsOfService) {
    termsLabel = translator('termsAndPrivacyLink', {
      termsHref: termsOfService,
      privacyHref: privacyPolicy,
    })
  } else if (termsOfService) {
    termsLabel = translator('termsLink', { href: termsOfService })
  } else if (privacyPolicy) {
    termsLabel = translator('privacyLink', { href: privacyPolicy })
  }
  if (termsLabel) {
    termsLabel = `${translator(
      'registerInvitationPage.agree.youAgreePrefix',
    )} ${termsLabel}`
  }

  return {
    fields: [
      {
        label: translator('password'),
        name: 'password',
        type: ElementType.PASSWORD,
      },
      {
        label: translator('passwordConfirmation'),
        name: 'passwordConfirmation',
        type: ElementType.PASSWORD,
      },
      {
        label: translator('registerInvitationPage.agree.toTermsLabel'),
        name: 'agreeToTerms',
        type: ElementType.SWITCH,
      },
      {
        fontSize: 12,
        label: termsLabel,
        largeMarginTop: true,
        type: BasicType.PLAINTEXT,
      },
      {
        label: translator('registerInvitationPage.submitLabel'),
        type: BasicType.SUBMIT,
      },
    ],
    header: translator('createPassword'),
    type: FormIdentifier.REGISTER_INVITATION,
  }
}

// TODO: Implement this method once we start using this component within ResetPasswordPage.tsx
const resetPasswordForm = () => null

const PasswordForm: React.FC<Props> = ({
  agreementsConfig,
  clearServerError,
  formIdentifier,
  handleSubmit,
  serverError,
}) => {
  const i18n = useI18n()
  const description = i18n.t('passwordRules')
  let form: Form | null = null

  switch (formIdentifier) {
    case FormIdentifier.REGISTER_INVITATION:
      form = registerInvitationForm(agreementsConfig, i18n.t)
      break
    default:
      form = resetPasswordForm()
      break
  }

  return form === null ? null : (
    <FormBuilder
      description={description}
      form={form}
      onChange={clearServerError}
      onSubmit={handleSubmit}
      serverError={serverError}
    />
  )
}

export default PasswordForm
