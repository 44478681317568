import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { useMutation } from '@apollo/client'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import { useCustomer } from '../../../configuration/hooks/useCustomer'
import {
  BasicType,
  ElementType,
} from '../../../src/components/forms/FormElementParts'
import { FormData } from '../../../src/hooks/useForm'
import { clearLocalPHI } from '../../../src/hooks/useLocalState'
import useServerError from '../../../src/hooks/useServerError'
import ForgotPasswordMutation from '../../graphql/ForgotPasswordMutation.gql'
import { FormIdentifier } from '../forms/FieldFactory'
import FormBuilder from '../forms/FormBuilder'

const ForgotPasswordPage = () => {
  const { pageTitle } = useCustomer()
  const [forgotPassword, { data }] = useMutation(ForgotPasswordMutation)
  const { clearServerError, serverError, setServerError } = useServerError()
  const i18n = useI18n()
  const navigate = useNavigate()

  const handleSubmitForm = React.useCallback(
    async ({ email, dob }: FormData) => {
      const { data, errors } = await forgotPassword({
        variables: { email, dob },
      })

      const errorMessage = errors?.[0].message
      if (data?.forgotPassword?.statusCode === 202) {
        clearServerError()
        navigate('/password/sent')
      } else if (errorMessage) {
        setServerError({ message: errorMessage, errors: {} })
      }
    },
    [clearServerError, forgotPassword, navigate, setServerError],
  )

  React.useEffect(clearLocalPHI, [])

  React.useEffect(() => {
    const message = data?.forgotPassword?.message

    message && setServerError({ message, errors: {} })
  }, [data, setServerError])

  // TODO: will need to add handling for these links to to through the router
  // once sign in and invitation are converted over
  const theForm = React.useMemo(
    () => ({
      fields: [
        {
          label: i18n.t('email'),
          name: 'email',
          placeholder: i18n.t('formPlaceholders.email'),
          type: ElementType.EMAIL,
        },
        {
          label: i18n.t('dateOfBirth'),
          name: 'dob',
          type: ElementType.DOB,
        },
        {
          label: i18n.t('sendInstructions'),
          type: BasicType.SUBMIT,
        },
        {
          label: `<a href='/sign_in'>${i18n.t('signIn')}</a>`,
          type: BasicType.FOOTER,
        },
        {
          fontSize: 13,
          label: `${i18n.t(
            'needInvitation',
          )} <a href="/invitation/resend">${i18n.t('sendRequest')}</a>`,
          type: BasicType.FOOTER,
        },
      ],
      header: i18n.t('forgotPasswordPage.header'),
      type: FormIdentifier.FORGOT_PASSWORD,
    }),
    [i18n],
  )

  return (
    <React.Fragment>
      <Helmet
        title={`${pageTitle} - ${i18n.t('forgotPasswordPage.pageTitle')}`}
      />
      <FormBuilder
        description={i18n.t('forgotPasswordPage.formDescription')}
        form={theForm}
        key={theForm.type}
        onChange={clearServerError}
        onSubmit={handleSubmitForm}
        serverError={serverError}
      />
    </React.Fragment>
  )
}

export default ForgotPasswordPage
