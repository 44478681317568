import React from 'react'
import { ServerError } from '../../account/components/forms/FieldFactory'

const defaultValue = { message: null, errors: {} }

const useServerError = (initialValue = defaultValue) => {
  const [serverError, setServerError] =
    React.useState<ServerError>(initialValue)
  const clearServerError = React.useCallback(() => {
    setServerError(defaultValue)
  }, [])
  return {
    clearServerError,
    serverError,
    setServerError,
  }
}

export default useServerError
