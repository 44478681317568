import { InvitationTokenResponse } from '@app/graphql-types/Account'
import React from 'react'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import {
  BasicType,
  ElementType,
  Field,
} from '../../../src/components/forms/FormElementParts'
import { FormIdentifier } from './FieldFactory'
import FormBuilder from './FormBuilder'

interface Props {
  agreementsConfig: InvitationTokenResponse
  clearServerError: any
  handleSubmit: any
  serverError: any
  viewIndex: number
}

type TranslatorSignature = (
  key: string,
  replacementMap?: Record<string, string>,
) => any

const passwordFields = (translator: TranslatorSignature) => ({
  fields: [
    {
      label: translator('password'),
      name: 'password',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('passwordConfirmation'),
      name: 'passwordConfirmation',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('registerInvitationPage.submitLabel'),
      type: BasicType.SUBMIT,
    },
    {
      label: `${translator(
        'alreadyHaveAccountMessage',
      )} <a href='/sign_in'>${translator('signIn')}</a>`,
      type: BasicType.FOOTER,
    },
  ],
  header: translator('createPassword'),
  type: FormIdentifier.REGISTER_INVITATION,
})

const supplementalTermFields = (
  agreementsConfig: any,
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Field[] => {
  if (!agreementsConfig) {
    return []
  }

  const fields: Field[] = []

  // Terms of use and privacy policy
  const { privacyPolicy, termsOfService } = agreementsConfig
  if (termsOfService && privacyPolicy) {
    fields.push({
      label: translator(
        'registerInvitationPage.agree.toTermsOfUseAndPrivacyPolicy',
        { privacyHref: privacyPolicy, termsHref: termsOfService },
      ),
      name: 'agreeToTermsAndPrivacyPolicy',
      type: ElementType.CHECKBOX,
    })
  } else if (termsOfService) {
    fields.push({
      label: translator('registerInvitationPage.agree.toTermsOfUse', {
        href: termsOfService,
      }),
      name: 'agreeToTerms',
      type: ElementType.CHECKBOX,
    })
  } else if (privacyPolicy) {
    fields.push({
      label: translator('registerInvitationPage.agree.toPrivacyPolicy', {
        href: privacyPolicy,
      }),
      name: 'agreeToPrivacyPolicy',
      type: ElementType.CHECKBOX,
    })
  }

  // Informed constent and notice of patient privacy
  const { informedConsent, noticeOfPrivacyPractices } = agreementsConfig
  if (informedConsent && noticeOfPrivacyPractices) {
    fields.push({
      label: translator(
        'registerInvitationPage.agree.toInformedConsentAndPrivacyPractices',
        { consentHref: informedConsent, noticeHref: noticeOfPrivacyPractices },
      ),
      name: 'agreeToInformedConsentAndPrivacyPractices',
      type: ElementType.CHECKBOX,
    })
  } else if (agreementsConfig.informedConsent) {
    fields.push({
      label: translator('registerInvitationPage.agree.toInformedConsent', {
        href: informedConsent,
      }),
      name: 'agreeToInformedConsent',
      type: ElementType.CHECKBOX,
    })
  } else if (agreementsConfig.noticeOfPrivacyPractices) {
    fields.push({
      label: translator('registerInvitationPage.agree.toPrivacyPractices', {
        href: noticeOfPrivacyPractices,
      }),
      name: 'agreeToPrivacyPractices',
      type: ElementType.CHECKBOX,
    })
  }

  // Patient financial responsibility
  const { patientFinancialResponsibility } = agreementsConfig
  if (patientFinancialResponsibility) {
    fields.push({
      label: translator(
        'registerInvitationPage.agree.toFinancialResponsibility',
        { href: patientFinancialResponsibility },
      ),
      name: 'agreeToFinancialResponsibility',
      type: ElementType.CHECKBOX,
    })
  }

  // SMS Marketing
  const { enableTextMessageMarketingAgreement } = agreementsConfig
  if (enableTextMessageMarketingAgreement) {
    fields.push({
      label: translator('registerInvitationPage.agree.toSmsMarketing', {
        href: 'https://www.cirrusmd.com/sms-marketing',
      }),
      required: false,
      name: 'agreeToTextMessageMarketingAgreement',
      type: ElementType.CHECKBOX,
    })
  }

  return fields
}

const termFields = (
  agreementsConfig: any,
  translator: TranslatorSignature,
): any => {
  const fields = supplementalTermFields(agreementsConfig, translator)

  fields.push(
    {
      label: translator('continue'),
      type: BasicType.SUBMIT,
    },
    {
      label: `${translator(
        'alreadyHaveAccountMessage',
      )} <a href='/sign_in'>${translator('signIn')}</a>`,
      type: BasicType.FOOTER,
    },
  )

  return {
    fields,
    header: translator('termsAndAgreements.heading'),
    type: FormIdentifier.REGISTER_INVITATION,
  }
}

const RegisterTermsForm: React.FC<Props> = ({
  agreementsConfig,
  clearServerError,
  handleSubmit,
  serverError,
  viewIndex,
}) => {
  const i18n = useI18n()
  const translationKey =
    viewIndex === 0 ? 'registerInvitationPage.pageDescription' : 'passwordRules'
  const description = i18n.t(translationKey)

  return (
    <FormBuilder
      description={description}
      form={
        viewIndex === 0
          ? termFields(agreementsConfig, i18n.t)
          : passwordFields(i18n.t)
      }
      onChange={clearServerError}
      onSubmit={handleSubmit}
      serverError={serverError}
    />
  )
}

export default RegisterTermsForm
