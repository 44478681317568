import React from 'react'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import { useCustomer } from '../../../configuration/hooks/useCustomer'
import {
  BasicType,
  ElementType,
} from '../../../src/components/forms/FormElementParts'
import { FormData } from '../../../src/hooks/useForm'
import useServerError from '../../../src/hooks/useServerError'
import { getQueryParamValue } from '../../../src/lib/uri'
import FormBuilder from '../../components/forms/FormBuilder'
import ResetPasswordMutation from '../../graphql/ResetPasswordMutation.gql'
import { Form, FormIdentifier } from '../forms/FieldFactory'

// TODO: will need to add handling for these links to to through the router
// once sign in and invitation are converted over
const form = (
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Form => ({
  fields: [
    {
      label: translator('newPassword'),
      name: 'password',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('newPasswordConfirmation'),
      name: 'passwordConfirmation',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('changeMyPassword'),
      type: BasicType.SUBMIT,
    },
    {
      label: translator('alreadyHaveAccountLink'),
      type: BasicType.FOOTER,
    },
    {
      fontSize: 13,
      label: translator('needInvitationLink'),
      type: BasicType.FOOTER,
    },
  ],
  header: translator('changeYourPassword'),
  type: FormIdentifier.CHANGE_PASSWORD,
})

const ResetPasswordPage = () => {
  const { pageTitle } = useCustomer()
  const i18n = useI18n()
  const [resetPassword, { data, error }] = useMutation(ResetPasswordMutation)
  const { clearServerError, serverError, setServerError } = useServerError()
  const handleSubmitForm = React.useCallback(
    (formData: FormData) => {
      const resetPasswordToken = getQueryParamValue('reset_password_token')
      resetPassword({
        variables: {
          ...formData,
          resetPasswordToken,
        },
      })
    },
    [resetPassword],
  )
  React.useEffect(() => {
    if (error && error.graphQLErrors.length) {
      const err: any = error.graphQLErrors[0]
      setServerError({ message: err.message, errors: err.fields })
    } else {
      clearServerError()
    }
  }, [clearServerError, error, setServerError])

  if (data && data.resetPassword) {
    window.location.href = '/'
  }

  const renderedForm = form(i18n.t)

  return (
    <React.Fragment>
      <Helmet title={`${pageTitle} - ${i18n.t('changeYourPassword')}`} />
      <FormBuilder
        description={i18n.t('passwordRules')}
        form={renderedForm}
        key={renderedForm.type}
        onChange={clearServerError}
        onSubmit={handleSubmitForm}
        serverError={serverError}
      />
    </React.Fragment>
  )
}

export default ResetPasswordPage
