query ValidInvitationToken($token: String!, $fromExternalSource: Boolean!) {
  validInvitationToken(token: $token, fromExternalSource: $fromExternalSource) {
    ... on StatusCode {
      message
      statusCode
    }
    ... on TermsAndAgreementsConfig {
      id
      customerName
      enableTextMessageMarketingAgreement
      informedConsent
      noticeOfPrivacyPractices
      patientFinancialResponsibility
      privacyPolicy
      programName
      termsAndAgreementsEnabled
      termsOfService
    }
  }
}
