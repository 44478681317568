import { useMutation } from '@apollo/client'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import { useCustomer } from '../../../configuration/hooks/useCustomer'
import {
  BasicType,
  ElementType,
} from '../../../src/components/forms/FormElementParts'
import { clearLocalPHI } from '../../../src/hooks/useLocalState'
import useServerError from '../../../src/hooks/useServerError'
import ResendInvitationMutation from '../../graphql/ResendInvitationMutation.gql'
import { Form, FormIdentifier } from '../forms/FieldFactory'
import FormBuilder from '../forms/FormBuilder'

const form = (
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Form => ({
  fields: [
    {
      label: translator('email'),
      name: 'email',
      placeholder: translator('formPlaceholders.email'),
      type: ElementType.EMAIL,
    },
    {
      label: translator('sendInstructions'),
      type: BasicType.SUBMIT,
    },

    {
      label: translator('alreadyHaveAccountLink'),
      type: BasicType.FOOTER,
    },
  ],
  header: translator('resendInvitationPage.pageTitle'),
  type: FormIdentifier.RESEND_INVITATION,
})

const ResendInvitationPage = () => {
  const i18n = useI18n()
  const { pageTitle } = useCustomer()
  const [resendInvitation, { data }] = useMutation(ResendInvitationMutation)
  const { clearServerError, serverError, setServerError } = useServerError()
  const location = useLocation()
  const handleSubmit = React.useCallback(
    (formData) => {
      resendInvitation({ variables: formData })
    },
    [resendInvitation],
  )

  React.useEffect(clearLocalPHI, [])

  React.useEffect(() => {
    const message =
      data?.resendInvitation?.message || (location?.state as any)?.message

    if (message) {
      setServerError({
        message,
        errors: {},
      })
    }
  }, [data, location, setServerError])

  const renderedForm = form(i18n.t)
  return (
    <React.Fragment>
      <Helmet
        title={`${pageTitle} - ${i18n.t('resendInvitationPage.pageTitle')}`}
      />
      <FormBuilder
        description={i18n.t('resendInvitationPage.formDescription')}
        form={renderedForm}
        key={renderedForm.type}
        onChange={clearServerError}
        onSubmit={handleSubmit}
        serverError={serverError}
      />
    </React.Fragment>
  )
}

export default ResendInvitationPage
