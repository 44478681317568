mutation ResetPassword(
  $resetPasswordToken: String!
  $password: String!
  $passwordConfirmation: String!
) {
  resetPassword(
    resetPasswordToken: $resetPasswordToken
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    accessToken
    createdAt
    expiresIn
    scope
    tokenType
  }
}
